/* all boxes styles */

@import '_bootstrap_variables.scss';
@import 'vars-colors.scss';
@import 'mix-fonts.scss';
@import 'mix-flex.scss';

.box {

  &-bg {
    &-primary {
      background-color: $primary-color!important;
      color: $white!important;
    }
    &-blue {
      background-color: $notify-blue!important;
      color: $white!important;
    }
    &-blue-light {
      background-color: $notify-blue-light!important;
      color: $white!important;
    }
    &-dark {
      background-color: $primary-dark;
      color: $white;
    }
    &-green {
      background-color: $notify-green!important;
      color: $white!important;
    }
    &-green-light {
      background-color: $notify-green-light!important;
      color: $white!important;
    }
    &-primary-light {
      background-color: $primary-light;
      color: $secondary-dark;
    }
    &-secondary-light {
      background-color: $secondary-light;
      color: $secondary-dark;
    }
    &-red {
      background-color: $notify-red!important;
      color: $white!important;
    }
    &-violet {
      background-color: $notify-violet!important;
      color: $white!important;
    }
    &-white {
      background-color: $white;
      color: $primary-dark;
    }
    &-yellow {
      background-color: $notify-yellow!important;
      color: $white!important;
    }
  }

  /*
  &-bo {
    &-primary {
      border: 1px $primary-color!important solid;
    }
    &-blue {
      border: 1px $notify-blue!important solid;
    }
    &-blue-light {
      border: 1px $notify-blue-light!important solid;
    }
    &-dark {
      border: 1px $primary-dark solid;
    }
    &-green {
      border: 1px $notify-green!important solid;
    }
    &-green-light {
      border: 1px $notify-green-light!important solid;
    }
    &-primary-light {
      border: 1px $primary-light solid;
    }
    &-secondary-light {
      border: 1px $secondary-light solid;
    }
    &-red {
      border: 1px $notify-red!important solid;
    }
    &-violet {
      border: 1px $notify-violet!important solid;
    }
    &-white {
      border: 1px $white solid;
    }
    &-yellow {
      border: 1px $notify-yellow!important solid;
    }
  }

  &-o {
    &-100 {
      opacity:1;
    }
    &-90 {
      opacity:0.9;
    }
    &-80 {
      opacity:0.8;
    }
    &-70 {
      opacity:0.7;
    }
    &-60 {
      opacity:0.6;
    }
    &-50 {
      opacity:0.5;
    }
    &-40 {
      opacity:0.4;
    }
    &-30 {
      opacity:0.3;
    }
    &-20 {
      opacity:0.2;
    }
    &-10 {
      opacity:0.1;
    }
  }
  */
  
}

.alert, .flash {
  position: relative;
  padding: 14px 16px;
  border: 0;
  border-radius: 8px;

  @include fs-14;
  line-height:1.5;

  margin-bottom: 1rem;

  &-danger {

    a {
      color: $white;
      text-decoration: underline;

      &:hover {
        color: $color-superlight;
        text-decoration: none;
      }
    }
  }
}

// do uprzątniecia spojrz na bootstrap
.box-rnd-14 {
  border-radius: 14px;
}

.box-shade {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.04);
}

.box- {
	&p-20 {
		padding:20px;
	}
	&p-30 {
		padding:30px;
	}
	&mt-30 {
		margin-top:30px;
	}
	&mbt-30 {
		margin-top:30px;
		margin-bottom:30px;
	}
}

/*
.box-p {

  &-10 {
    padding:10px;
  }
  &t-10 {
    padding-top:10px;
  }
  &b-10 {
    padding-bottom:10px;
  }
  &tb-10, &bt-10 {
    padding-top:10px;
    padding-bottom:10px;
  }

  &-20 {
    padding:20px;
  }
  &t-20 {
    padding-top:20px;
  }
  &b-20 {
    padding-bottom:20px;
  }
  &tb-20, &bt-20 {
    padding-top:20px;
    padding-bottom:20px;
  }

  &-30 {
    padding:30px;
  }
  &t-30 {
    padding-top:30px;
  }
  &b-30 {
    padding-bottom:30px;
  }
  &tb-30, &bt-30 {
    padding-top:30px;
    padding-bottom:30px;
  }

  &-40 {
    padding:40px;
  }
  &t-40 {
    padding-top:40px;
  }
  &b-40 {
    padding-bottom:40px;
  }
}

.box-m {
  &-20 {
    margin:20px;
  }
  &t-20 {
    margin-top:20px;
  }
  &b-20 {
    margin-bottom:20px;
  }
  &l-20 {
    margin-left:20px;
  }
  &r-20 {
    margin-right:20px;
  }
  &tb-20, &bt-20 {
    margin-top:20px;
    margin-bottom:20px;
  }
  &-30 {
    margin:30px;
  }
  &t-30 {
    margin-top:30px;
  }
  &b-30 {
    margin-bottom:30px;
  }
  &tb-30, &bt-30 {
    margin-top:30px;
    margin-bottom:30px;
  }
}
*/

.box-eq {
  height:100%!important;
}

.box-table {

  .row {
    display:table!important;
    border-spacing: 15px 0;
    padding:0!important;
  }

  .box-eq {
    display: table-cell!important;
  }
}

.box-horizontal-scroll-container {

	position: relative;

	.box-horizontal-scroll {
		overflow-x: auto;
		overflow-y: hidden;
		white-space: nowrap;
		-ms-overflow-style: none;  // IE 10+
		scrollbar-width: none;  // Firefox

		&::-webkit-scrollbar {
			display: none;  // Safari and Chrome
		}

		.row {
			display: block;
		}
		
		&-box {
			float: none;
			padding: 15px;
		}

		&#news-list {

			.box-horizontal-scroll-box {
				display: inline-block;
				white-space: normal;
				float: none;
				padding: 15px;
			}
		
		}
		

	}

	i {
		position: absolute;
		width:34px;
		height:34px;
		line-height: 34px;
		color: $gray-dark;
		background-color: $secondary-light;
		text-align: center;
		display: inline-block;
		top:50%;
		margin-top: -17px;
		box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
		cursor: pointer;

		&.horizontal-scroll-btn-left {
			left:-17px;
		}

		&.horizontal-scroll-btn-right {
			right:-17px;
		}
	}
}

paging {
	
	.rounded-circle {
		@include fs-16;
		display: inline-block;
		width: 34px;
		height: 34px;
		line-height: 34px;
		text-align: center;
		vertical-align: middle;
		margin-right: 10px;
		
		@media (max-width: 420px) {
			width: 25px;
			height: 25px;
			line-height: 25px;
			font-size: 13px;
			margin-right: 9px;
		}
		
		&:last-of-type {
			margin-right: 0px;
		}
	}
	
	.p-arrow {
		@include fs-18;
		background-color: $white;
		box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.08);
		color: $gray-dark;
		
		&:hover:not(.disabled) {
			background-color: $secondary-color;
			color: $white;
		}
		
		&.disabled {
			background-color: $gray-superlight;
		}
	}
	
	.p-page {

		&:not(.active) {
			background-color: $white;
			box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.08);
			color: $gray-dark;
			&:hover {
				color: $secondary-color;
			}
		}
		
		&.active {
			background-color: $primary-color;
			box-shadow: 0px 0px 6px 0px rgba(251, 100, 45, 0.4);
			color: $white;
		}
		
	}
	
}

.infobox {
	
	display: flex;
	align-items: stretch;
	border-radius: 14px;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .04);
	margin-bottom: 15px;
	font-size: 13px;
	background: #fdfdfd;
	
	.left {
		display: flex;
		color: #fff;
		padding: 0px 15px;
		font-size: 24px;
		border-top-left-radius: 14px;
		border-bottom-left-radius: 14px;
		
		.ico {
			align-self: center;
		}
		
	}
	
	.msg {
		padding: 12px 12px 12px 24px;
	}
	
}

