/* all boxes styles */

@import '_bootstrap_variables.scss';
@import 'vars-colors.scss';
@import 'mix-fonts.scss';

.pageinfobox {
	
	text-align: justify;
	
	p:last-of-type {
		margin-bottom: 0px;
	}
	
}

.auction-info {
	margin-bottom: 30px;
	padding: 20px;

	.logo-head {
		
		img {
			display: inline-block;
			float: left;
			width: auto;
			height: auto;
			max-width: 100%;
			max-height: 40px;
		}
    
		/*
		.stream {
			width:40px;
			height:40px;
			line-height: 40px;
			text-align: center;
			font-size:24px;
			border-radius:50%;
			background-color: rgba($notify-green, 0.05);
			color: $notify-green;
			margin-right: 10px;
		}
		*/
		
		.date {
			@include font-main;
			color: $gray-dark;
		}
		
		h1 {
			@include font-head;
			@include fs-28;
			line-height: 36px;
			color: $primary-dark;
			margin-bottom: 5px;
			padding: 0;
			
			@media (max-width: 767px) {
				@include fs-20;
				line-height: 26px;
			}
			
			span {
				display: block;
				font-size: 14px;
				line-height: 20px;
				color: $gray-dark;
			}
		
		}
		
		h2 {
			@include font-main;
			@include fs-18;
			@include fw-500;
			line-height: 43px;
			display: inline-block;
			margin:0 0 0 12px;
			padding:0;
			float: left;
		}
		
		h3 {
			@include fs-16;
			@include fw-400;
			float: right;
			display: inline-block;
			text-align: right;
			strong {
				@include fw-600;
				&::before {
					white-space: pre;
					content: '\a';
				}
			}
		}
    
		.jumptolot-container {
			width: 156px;
			height: 32px;
			border-radius: 60px;
			background-color: $primary-light;
			
			input {
				display: block;
				float: left;
				padding:0 0 0 12px;
				width:122px;
				height:32px;
				line-height: 32px;
				border:0;
				margin: 0;
				background-color: $primary-light;
				@include fs-13;
				color: $gray-dark;
				&:focus {
					box-shadow: none;
				}
			}
			
			.arrow {
				cursor: pointer;
				line-height: 32px;
				float: right;
				padding: 0px 10px;
				border-left: 1px solid $gray-lighter;
				color:var(--bs-primary);
			}
		}
    
	}

	.icon {
		width: 40px;
		height: 40px;
		line-height: 40px;
		min-width: 40px;
		text-align: center;
		font-size: 24px;
		display: inline-block;
		background-color: rgba($primary-dark, 0.05);
		color: $primary-dark;

		&.m-icon-primary {
			background-color: rgba(var(--bs-primary), 0.05);
			color: var(--bs-primary);
		}
		&.m-icon-green {
			background-color: rgba($notify-green, 0.05);
			color: $notify-green;
		}
		&.m-icon-violet {
			background-color: rgba($notify-violet, 0.05);
			color: $notify-violet;
		}
		&.m-icon-red {
			background-color: rgba($notify-red, 0.05);
			color: $notify-red;
		}
		&.m-icon-yellow {
			background-color: rgba($notify-yellow, 0.05);
			color: $notify-yellow;
		}
		&.m-icon-white {
			background-color: rgba(#fff, 1);
			color: #000;
		}
		&.m-icon-black {
			background-color: rgba(#000, 0.05);
			color: #000;
		}
	}

	.usersearch {
	  
		a {
			font-size: 15px;
		}
		
		small {
			font-size: 11px;
		}

	}

	&-after {

		.box {
			margin-bottom:10px;

			div {
				@include font-head;
				padding:10px 15px;

				span.title {
					@include fs-13;
					@include fw-400;
				}

				strong.value {
					margin-top: -3px;
					@include fs-16;
					@include fw-400;
				}
			}

		}
	}
	
	.cardsection {
		
		border: 0px;
		
		.card-header {
			border-radius: 10px;
			font-weight: 700;
			background: #f5f5f5;
			padding: 10px;
			font-size: 14px;
		}
		
		.stepstable {
			td {
				font-size:12px;
				
				&.step {width: 100px;}
				&.sign {width: 60px; font-size: 24px; font-weight: 300; text-align: center;}
				&.value {width: 100px; text-align: right;}
			}
		}
		
		.long-content {
			max-height: 400px;
			position: relative;
			overflow: hidden;
		
			.read-more {
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				text-align: center;
				margin: 0;
				padding-top:80px;
				padding-bottom:5px;
				background-image: linear-gradient(to bottom, transparent, #fefefe);
			}
		}
	}

}

@media (max-width: 767px) {
	
	.auction-info {
		
		.logo-head {
			
			img {
				display: block;
				float: none;
			}
			
			h2 {
				display: block;
				margin:8px auto 0 auto;
				float: none;
			}
		}

	}
	
} // @media (max-width: 767px) EO

.auctions-list {

	.index-bar {

		.summary {
			line-height: 23px;
			color: $gray-dark;
			@include fw-500;
			@include fs-16;

			sub {
				bottom: 0;
				font-size:65%;
			}

			.btn {
				margin-left: 0.25rem;
			}
		}

		.controls {
			text-align: right;
			
			.showcount {
				border-radius: 30px;
				width: 50px;
				margin-left: 5px;
				background-color: #fff;
			}
			
			.dropdown-menu {
				width: 50px;
				font-size: 13px;
				min-width: auto;
				margin-top: 3px;
				
				.dropdown-item {
					
					&:hover {
						cursor: pointer;
					}
					
				}
			}
		}
	}

	.bootstrap-select .dropdown-toggle {
		outline: 0px !important;
	}
	.dropdown-menu li {
		font-size:12px;
	}

	.box-auction {

		position: relative;

    a {
		
      display: block;

		.auction-head {
			
			position: relative;

			.poster {
				width:100%;
				border-radius: 14px 14px 0 0;
			}

			.auction-registered {
				position: absolute;
				z-index: 2;
				left: 16px;
				bottom: -14px;
				width: 26px;
				height: 26px;
				background-color: #ffc164;
				color: #ffffff;
				@include font-head;
				text-align: center;
				line-height: 26px;
				font-size:18px;
			}

			.auction-stream {
				position: absolute;
				z-index: 2;
				right: 83px;
				bottom: -14px;
				width: 26px;
				height: 26px;
				background-color: #4cd1c7;
				color: #ffffff;
				@include font-head;
				text-align: center;
				line-height: 26px;
				font-size: 14px;
			}
			
			.auction-limits {
				position: absolute;
				z-index: 2;
				right: 83px;
				bottom: -14px;
				width: 26px;
				height: 26px;
				background-color: #00b0ff;
				color: #ffffff;
				@include font-head;
				text-align: center;
				line-height: 26px;
				font-size: 14px;
			}
			
			.auction-charity {
				position: absolute;
				z-index: 2;
				right: 110px;
				bottom: -14px;
				width: 26px;
				height: 26px;
				background-color: $notify-red;
				color: #ffffff;
				@include font-head;
				text-align: center;
				line-height: 26px;
				font-size: 14px;
			}
			.auction-badges {
				position: absolute;
				z-index: 2;
				right: 0px;
				top: 4px;
				
				img {
					width: 40px;
					height: 40px;
					padding: 3px;
					background: #fff;
					border: 2px solid $gray-light;
					border-radius: 50%;
					float: right;
					margin-right: 5px;
					-webkit-box-shadow: 1px 1px 2px 0px rgba(204, 204, 204, 1);
					-moz-box-shadow: 1px 1px 2px 0px rgba(204, 204, 204, 1);
					box-shadow: 1px 1px 2px 0px rgba(204, 204, 204, 1);
				}
			}

			.auction-marker {
				position: absolute;
				z-index: 2;
				right: 20px;
				bottom: -27px;
				width: 54px;
				height: 54px;
				background-color: $primary-light;
				color: $primary-dark;
				@include font-head;
				text-align: center;
				text-transform: uppercase;
				padding-left: 2px;

				strong {
					@include fs-18;
					@include fw-400;
					display: block;
					height: 27px;
					padding-top: 10px;
					line-height: 18px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis
				}
				em {
					display: block;
					line-height: 1.5;
					@include fs-10;
					@include fw-400;
					font-style:normal;
				}
				span {
					display: block;
					line-height: 1.5;
					@include fs-12;
					@include fw-400;
				}

				&.box-bg-violet, &.box-bg-red {
					padding-left: 0;
					
					strong {
						height: 54px;
						line-height: 54px;
						padding:0;
						@include fs-12;
					}
				}
				
				&.today {
					padding-left: 0;

					strong {
						font-size: 15px;
						padding-top: 11px;
						line-height: 18px;
						height: 29px;
					}
					span {
						font-size: 10px;
					}
				}
			}

			.pulse {
				box-shadow: 0 0 0 rgba(243, 3, 60, 0.4);
				animation: auction-marker-pulse 1.5s infinite;
			}

			@-webkit-keyframes auction-marker-pulse {
				0% {
					-webkit-box-shadow: 0 0 0 0 rgba(243, 3, 60, 0.4);
				}
				70% {
					-webkit-box-shadow: 0 0 0 15px rgba(243, 3, 60, 0);
				}
				100% {
					-webkit-box-shadow: 0 0 0 0 rgba(243, 3, 60, 0);
				}
			}
			@keyframes auction-marker-pulse {
				0% {
					-moz-box-shadow: 0 0 0 0 rgba(243, 3, 60, 0.4);
					box-shadow: 0 0 0 0 rgba(243, 3, 60, 0.4);
				}
				70% {
					-moz-box-shadow: 0 0 0 15px rgba(243, 3, 60, 0);
					box-shadow: 0 0 0 15px rgba(243, 3, 60, 0);
				}
				100% {
					-moz-box-shadow: 0 0 0 0 rgba(243, 3, 60, 0);
					box-shadow: 0 0 0 0 rgba(243, 3, 60, 0);
				}
			}

		.livewidget {
			position: relative;

			.content {
				position: absolute;
				top: 0px;
				width: 100%;
				height: 100%;
				color: $primary-dark;
				border-bottom:1px solid #f5f5f5;

				.nr {
					position: absolute;
					top: 8px;
					left: 6px;
					line-height:30px;
					width: 30px;
					height: 30px;
					border-radius: 50%;
					background-color: $notify-green;
					color: #fff;
					@include font-head;
					@include fs-10;
					text-align: center;
					text-transform: uppercase;
				}

				.data {
					position: relative;
					top: 5px;
					left: 0px;
					padding-left: 42px;
					padding-right: 5px;
					@include fs-12;

					.name {
						text-overflow: ellipsis;
						white-space: nowrap;
						overflow: hidden;
					}
					.price {
						color: var(--bs-primary);
					}
				}

				.img {
					padding-top:5px;
					text-align: center;
					overflow: hidden;
				}

				.pause {
					padding-top: 0.5rem;
					text-align: center;
					height: 100%;

					.time {
						color: $notify-green;
					}

					.count-down {
						margin: 10px 0 0 0;
						padding: 0;
						font-family: sans-serif;
						text-align: center;
						height: auto;

						li {
							margin: 0px 5px 0px 0px;
							padding:0;
							list-style: none;
							display: inline-block;
							min-height: 40px;

							div {
								margin: 0 !important;
								padding: 0;
							}
							input {
								font-weight: normal !important;
								font-size: 14px !important;
								margin-top: -4px !important;
								width: 25px !important;
								height: 45px !important;
								text-align: center;

							}
							input.small {
								font-size:30px !important;
							}
							span {
								display: block;
								text-transform: uppercase;
								margin-top: -21px;
								font-size: 6px;
								position: relative;
								z-index: 9 !important;
								color: #888;
							}
						}
					}
				}
			}
		}

      }

      .auction-content {
        padding: 18px 20px 21px 20px;
        line-height:1.5;
        color: $gray-dark;
        @include fs-12;

		.company-logo {
			display: inline-block;
			height: auto;
			max-height: 30px;
			max-width: 100%;
			width: auto;
		}

		p {
			margin: 20px 0 6px 0;
		}

        &.content-big {

          .company-logo {
            display: inline-block;
          }
          h2 {
            line-height: 32px;
            @include font-main;
            @include fs-18;
            @include fw-500;
            margin-left: 12px;
            display: inline-block;
          }
          h3 {
            @include font-head;
            @include fs-22;
            @include fw-400;
          }
        }


        h3 {
          line-height:1.36;
          color:$primary-dark;
          @include fs-14;
          @include fw-400;
        }
      }

      &.hover-underline {
        &:hover {

          text-decoration: underline;
        }
      }
    }

    & .auction-action, &-internet .auction-action {
		
		padding-top: 10px;

      .btn:hover {
        box-shadow: none !important;
      }

      .btn, .btn-link {
        margin:0;

      }

    	.auction-countdown {
    		font-family: monospace;
    	}
    }

    &-internet {
      position:relative;
      padding:20px;

      h3 {
        margin:0 0 22px 0;
        line-height:16px;
        @include font-main;
        @include fw-500;
        @include fs-16;
      }

      .act-white {
        display:block;
        margin-bottom:21px;
        color:inherit;
        text-decoration: none;
        clear:both;
        height:30px;

        &:hover h4 {
          color:inherit;
          text-decoration: underline!important;
        }

        img {
          height:30px;
          float: left;
          box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.04);
        }

        h4 {

          float:left;
          width:55%;
          margin:0 0 0 17px;
          padding:0;
          height: 30px;
          line-height: 15px;
          @include fs-12;
          @include fw-400;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
}


.auctions {
	
	&-lots {
		
		&-list {
			padding:20px 20px;
			margin-bottom:30px;
			@include clearfix;

			&-filters {
				
				.btn {
					@include fs-12;
					letter-spacing: normal;
					text-transform: none;
					background: $primary-light;
					border: 0;
					font-weight: 400;
					padding: 6px 12px;
				}
				
				.filter-container {
					
					a.change {
						margin-left: 5px;
						background: $primary-light;
						color: $gray-dark;
						border-radius: 50%;
						padding: 2px 6px;
						font-size: 15px;
					}
				}
				
				.jumptolot-container {
					width: 156px;
					height: 32px;
					border-radius: 60px;
					background-color: $primary-light;

					input {
						display: block;
						float: left;
						padding:0 0 0 12px;
						width: 122px;
						height: 32px;
						line-height: 32px;
						border: 0;
						margin: 0;
						background-color: $primary-light;
						@include fs-13;
						color: $gray-dark;
						&:focus {
							box-shadow: none;
						}
					}
					
					.arrow {
						cursor: pointer;
						line-height: 32px;
						float: right;
						padding: 0px 10px;
						border-left: 1px solid $gray-lighter;
						color: var(--bs-primary);
					}
				}
			}
      
			.lotslist {
				
				.lotbox {
					margin-bottom: 10px;
					padding-left: 5px;
					padding-right: 5px;
					
					
					.before {
						background: #f5f5f5;
						border: 1px #eaeaea solid;
						border-radius: 10px;
						padding: 0;
						text-align: justify;
						
						p:last-of-type {
							margin-bottom: 0px;
						}
					}

				}
				
			}

			.lotinfo {

				border: 1px #eaeaea solid;
				border-radius: 10px;
				padding: 0;
				height: 317px;

				&:hover {
					border: 1px $gray-superlight solid;
					-webkit-box-shadow: 0px 0px 5px 0px rgba(200,200,200,0.3);
					-moz-box-shadow: 0px 0px 5px 0px rgba(200,200,200,0.3);
					box-shadow: 0px 0px 5px 0px rgba(200,200,200,0.3);
				}
				
				&.moreinfo {
					height: 344px;
				}

				.topholder {
					height:20px;
				}

				.top {
					height: 44px;
					@include clearfix;
					position: relative;

					.nr {
						@include fs-12;
						@include font-head;
						display: block;
						position: absolute;
						left: 16px;
						top: 13px;
						color: $primary-dark;
					}
					
					.bp {
						position: absolute;
						left:55px;
						top:13px;
						color: $notify-violet;
					}

					.icons {
					
						.ico {
							display: block;
							position: absolute;
							top: 10px;
							right: 16px;
							border-radius: 50%;
							background-color: #f5f5f5;
							width: 24px;
							height: 24px;
							line-height: 24px;
							border:1px solid #efefef;
							text-align: center;
							-webkit-box-shadow: 0px 0px 5px 0px rgba(200,200,200,0.5);
							-moz-box-shadow: 0px 0px 5px 0px rgba(200,200,200,0.5);
							box-shadow: 0px 0px 5px 0px rgba(200,200,200,0.5);
							
							&.observestar {
								font-size:15px;
								color: #ccd0d4;
								
								&.active {
									color: var(--bs-primary);
								}
								
								&:hover {
									color: var(--bs-primary);
								}
							}

						}
					}
					
					.countdown {
						position: absolute;
						top: 38px;
						right: 12px;
						@include fs-9;
						padding: 2px 5px;
						z-index: 3;
						background: #fff;
						border-radius: 14px;
						border:1px solid #efefef;
						text-align: center;
						font-family: monospace;
						-webkit-box-shadow: 0px 0px 5px 0px rgba(200,200,200,0.5);
						-moz-box-shadow: 0px 0px 5px 0px rgba(200,200,200,0.5);
						box-shadow: 0px 0px 5px 0px rgba(200,200,200,0.5);
						
						.spinner {
							width: 13px;
							height: 13px;
						}
					}
				}

				.imgbox {
					position: relative;
					text-align: center;
					margin:0 19px;
					height: 155px;
					
					.images {
						height: 100%;
						display: flex;
						flex-direction: row;
						flex-wrap: nowrap;
						justify-content: center;
						align-items: center;
						align-content: center;
					
						a {
							display: inline-block;
							margin: 0 auto;
						}
						
						.item {
							margin: 0 auto;
							display: inline-block;
						//	max-height: 155px;
						//	height: auto;
						//	width: 100%;
							max-height: 155px;
							width: auto;
						/*	aspect-ratio: attr(width) / attr(height);	*/
						}
						
					}
				
					.imgicons {
						position: absolute;
						bottom:-11px;
						height:24px;
						left:0;
						right:0;
					
						.rounded-circle {
							margin:0 2px;
							background-color: #fff;
							width: 24px;
							height: 24px;
							border: 1px solid #efefef;
							text-align: center;
							-webkit-box-shadow: 0px 0px 5px 0px rgba(200,200,200,0.5);
							-moz-box-shadow: 0px 0px 5px 0px rgba(200,200,200,0.5);
							box-shadow: 0px 0px 5px 0px rgba(200,200,200,0.5);
							
							&.video {
								color: #fff;
								background: #ce1312;
								border-color: #ca0030;
							}

							&.toppop {
								color: #ecc532;
								background: #333b4e;
								border-color: #151921;
							}
						}

						img {
							margin-top: 2px;
							max-height: 17px;
							max-width: 17px;
						}
						.ico {
							font-size:15px;
						}
						
					}
				
				}

				.condition {
					@include fs-12;
					@include fw-400;
					color: $notify-green;
					height: 15px;
					line-height: 15px;
					margin-top:15px;
					text-align: center;
					
					.hot {
						color: $notify-red;
					}
					
				}

				.caption {

					padding: 0 15px 10px 15px;
					
					a.title {
						
						display: block;
						margin-bottom: 5px;

						h2 {
							@include fs-13;
							@include fw-400;
							@include font-main;
							color: $primary-dark;
							line-height: 1.2;
							height: 31px;
							margin: 0px;
							text-align: center;
							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 2;
							-webkit-box-orient: vertical;
							
						}
						
					}

					.company {
						display: block;
						color: var(--bs-primary);
						display: block;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
						&:hover {
							text-decoration: underline;
						}
					}
				
					.date {
						text-align: center;
						height: 15px;
						line-height: 15px;
						@include fs-10;
						@include fw-400;
						color: $gray-dark;

						i {
							@include fs-14;
							line-height: inherit;
							vertical-align: top;
						}
					}
				
					.buttonbox {
						height: 40px;
						white-space: nowrap;
						
						.btn {
							letter-spacing: 0px;
						}
						
						.gavel {
							font-size: 90%;
						}
					}
					
					.winner {
						@include fs-12;
						padding-left: 10px;
						padding-right: 10px;
						margin: 5px auto 0px auto;
						font-weight: 500;
						text-transform: uppercase;
						display: block;
						border: 1px solid $notify-blue;
						background: $notify-blue;
						color: #fff;
					}
					.looser {
						@include fs-12;
						padding-left: 10px;
						padding-right: 10px;
						margin: 5px auto 0px auto;
						font-weight: 500;
						text-transform: uppercase;
						display: block;
						border: 1px solid $notify-red;
						background: #fff;
						color: $notify-red;
					}
					
				}
				
				&.tobid {
					border: 1px solid $notify-green-light;
				}
				
				video.mini {
					position: absolute;
					left: 15px;
					top: 40px;
					border-radius: 4px;
					height: 40px;
					-webkit-box-shadow: 2px 2px 4px 0px rgba(173, 173, 173, 1);
					-moz-box-shadow: 2px 2px 4px 0px rgba(173, 173, 173, 1);
					box-shadow: 2px 2px 4px 0px rgba(173, 173, 173, 1);
				}
			}
      
			.bigbox {
				
				height: 644px;
				
				&.internet {
					height: 698px;
					
					.lotinfo {
						height: 698px;
						
						.imgbox {
							height: 494px;
						}
					}
				}
					
				@media (max-width: 500px) {
					height: auto;
				}
				
				.lotinfo {
					height: 644px;

					@media (max-width: 500px) {
						height: 570px;
					}
					@media (max-width: 400px) {
						height: 430px;
					}
					@media (max-width: 300px) {
						height: 420px;
					}
					
					.imgbox {
						height: 480px;
						
						@media (max-width: 500px) {
							height: 400px;
						}
						@media (max-width: 400px) {
							height: 250px;
						}
						
						.images {
							.item {
								max-height: 480px;
								@media (max-width: 500px) {
									max-height: 400px;
								}
								@media (max-width: 400px) {
									max-height: 250px;
								}
							}
						}
					}
				}
			}
    
			.notfound {
				
				.bigicon {
					font-size: 30px;
					width: 50px;
					height: 50px;
					border-radius: 50%;
					text-align: center;
					line-height: 50px;
					background: $gray-light;
					color: #fff;
					margin: 20px;
					display: inline-block;
				}
				
			}
				
			.chips {
				margin-bottom: 20px;
				padding-bottom: 15px;
				border-bottom: 1px solid $gray-superlight;
				
				.chip {
					display: inline-block;
					margin-right: 10px;
					padding: 5px 10px;
					background: $gray-superlight;
					border-radius: 12px;
					color: $primary-dark;
					
					&:hover {
						color: $white;
						background: $notify-red;
						
						.remove {
							color: $white;
						}
					}
					
					.remove {
						color: $notify-red;
						margin-left:5px;
						
					}
					
				}
				
			}
			
			ol.breadcrumb {
				display:  block !important;
				padding: 5px;
				background: transparent;
				white-space: nowrap;
				-webkit-overflow-scrolling: touch;
				-ms-overflow-style: none;
				margin-bottom: 15px;
				overflow-x: scroll;
				
				&::-webkit-scrollbar {
					display: none;
				}
				
				.breadcrumb-item {
					overflow-y: visible;
					display: inline-block;
					
					@media (min-width: 769px) {
						
						&:hover > .breadcrumb-item-apron {
							display: block;
						}
						
					}
					
					.breadcrumb-item-apron {
						background-color: #fff;
						border: 1px solid #c3c3c3;
						box-sizing: border-box;
						display: none;
						padding: 5px 10px;
						position: absolute;
						z-index: 3000;
						margin-left: 15px;
						min-width: 150px;
						box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
						border-radius: 6px;
						
						&.long {
							column-count: 2;
							-moz-column-count: 2;
							-webkit-column-count: 2; 
							column-gap: 1em;
							-moz-column-gap: 1em;
							-webkit-column-gap: 1em;
						}
							
						&.show {
							display: block;
						}
						
						a {
							padding: 6px 6px;
							color: $secondary-dark;
							display: block;
							max-width: 240px;
							
							.name {
								max-width: 200px;
								text-overflow: ellipsis;
								white-space: nowrap;
								overflow: hidden;
								display: inline-block;
							}
							
							&.active {
								color: var(--bs-primary);
							}
							
							&:hover {
								color: var(--bs-primary);
							}
							
							.count {
								color: var(--bs-primary);
								float: right;
								padding-left: 10px;
							}
						}
					
					}
				}
			}
			
			.topjump {
				font-size: 18px;
				width: 34px;
				height: 34px;
				line-height: 34px;
				padding: 0px;
				background-color: $white;
				box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.08);
				color: $gray-dark;
				
				&:hover {
					background-color: $secondary-color;
					color: $white;
				}
				
				@media (max-width: 420px) {
					width: 25px;
					height: 25px;
					line-height: 25px;
				}
				
			}
			
		}
	
	}
}

.category-auctions {
	
	.auction-box {
		
		position: relative;
		
		a {
			display: block;
			color: inherit;
		}
		
		img {
			width: auto;
			height: 60px;
		}
		
		.company {
			color: var(--bs-primary);
			word-wrap: break-word;
			height: 1rem;
			overflow: hidden;
		}
	}
	
}

#limitsframe {
	position: fixed;
	z-index: 100;
	bottom: 5px;
	left: 10px;
	width: 400px;
	background: #fff;
	-webkit-box-shadow: 0px 0px 2px 1px rgba(195,195,195,0.5);
	-moz-box-shadow: 0px 0px 2px 1px rgba(195,195,195,0.5);
	box-shadow: 0px 0px 2px 1px rgba(195,195,195,0.5);
	
	a.list-group-item {
		color: #000;
	}
	
	.name {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space:  nowrap;
	}
	
	.img {
		img {
			display: inline-block;
			text-align: center;
			max-height:40px;
		}
	}

	.value {
		font-weight:700;
	}
	
}

.carouselholder {
	
	position: relative;
	min-height: 130px;
	@media (min-width: 767px) {
		min-width: auto;
	}

	.carouselbox {
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 2;
		width: 146px;
		height: 155px;
		margin: 0;
		-webkit-perspective: 400px;
		perspective: 400px;
		-webkit-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
	
		.carouselbox-content {
			position: absolute;
			width: 100%;
			height: 100%;
			-webkit-transform-style: preserve-3d;
			transform-style: preserve-3d;
			-webkit-transform: translateZ(-191px) rotateY(0);
			transform: translateZ(-191px) rotateY(0);
			-webkit-animation: carouselbox 10s infinite cubic-bezier(1, 0.015, 0.295, 1.225) forwards;
			animation: carouselbox 10s infinite cubic-bezier(1, 0.015, 0.295, 1.225) forwards;
		}
		
		.carouselbox-item {
			position: absolute;
			top: 0;
			left: 0;
			width: 146px;
			height: 155px;
			border-radius: 6px;
			background-color: #fff;
			background-position: center;
			background-size: contain;
			background-repeat: no-repeat;
			border: 1px solid #ddd;
			-webkit-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.20);
			-moz-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.20);
			box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.20);
		}
		
		.carouselbox-item:nth-child(1) {
			-webkit-transform: rotateY(0) translateZ(141px);
			transform: rotateY(0) translateZ(141px);
		}
		
		.carouselbox-item:nth-child(2) {
			-webkit-transform: rotateY(120deg) translateZ(141px);
			transform: rotateY(120deg) translateZ(141px);
		}
		
		.carouselbox-item:nth-child(3) {
			-webkit-transform: rotateY(240deg) translateZ(141px);
			transform: rotateY(240deg) translateZ(141px);
		}
		
		@-webkit-keyframes carouselbox {
			0%, 17.5% {
				-webkit-transform: translateZ(-141px) rotateY(0);
				transform: translateZ(-141px) rotateY(0);
			}
			27.5%, 45% {
				-webkit-transform: translateZ(-141px) rotateY(-120deg);
				transform: translateZ(-141px) rotateY(-120deg);
			}
			55%, 72.5% {
				-webkit-transform: translateZ(-141px) rotateY(-240deg);
				transform: translateZ(-141px) rotateY(-240deg);
			}
			82.5%, 100% {
				-webkit-transform: translateZ(-141px) rotateY(-360deg);
				transform: translateZ(-141px) rotateY(-360deg);
			}
		}
		@keyframes carouselbox {
			0%, 17.5% {
				-webkit-transform: translateZ(-141px) rotateY(0);
				transform: translateZ(-141px) rotateY(0);
			}
			27.5%, 45% {
				-webkit-transform: translateZ(-141px) rotateY(-120deg);
				transform: translateZ(-141px) rotateY(-120deg);
			}
			55%, 72.5% {
				-webkit-transform: translateZ(-141px) rotateY(-240deg);
				transform: translateZ(-141px) rotateY(-240deg);
			}
			82.5%, 100% {
				-webkit-transform: translateZ(-141px) rotateY(-360deg);
				transform: translateZ(-141px) rotateY(-360deg);
			}
		}
	}

}



