/* main search form */

@import 'vars-colors.scss';
@import 'mix-fonts.scss';

.hide-search-panel-desktop .main-search-panel {
	display: none;
}

.main-search-panel {

	#search-form {
		display: flex;
		width: 665px;
		height: 47px;
		margin: 36px auto 30px auto;
		border-radius: 80px;
		box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.2);
		background-color: $white;
		position: relative;

		.searchtext {
			
			display: inline-block;
			width: 520px;
			height: 41px;
			line-height: 44px;
			margin-top: 3px;
			border: 0;
			background-color: transparent;
			color: $gray-dark;
			@include fs-14;
			@include fw-400;

			&:focus {
				outline: none;
			}
		}
		
		.searchtype {
			
			.dropdown-toggle {
				@include fs-14;
				text-transform: none;
				
				&:focus, &:hover {
					outline: none;
					box-shadow: none;
				}
				&:after {
					color: var(--bs-primary);
				}
			}
			
			.dropdown-menu {
				
				.dropdown-item {
					@include fs-14;
					
					&:hover {
						cursor: pointer;
					}
					
				}
				
			}
		}

		/*
		.dropdown {
			width: auto;
			line-height: 47px;
			margin-left: auto;
		}

		.dropdown-item {
			@include fs-14;
			color: $primary-dark;
			padding: 10px;
			line-height: 16px;

			&:hover, &.active {
				background-color: $primary-light;
				text-decoration: none;
			}

			&.selected {
				color: $primary-color;
			}
		}

		.dropdown-toggle {
			display: inline-block;
			width: auto;
			margin:0;
			background-color: transparent;
			color: $black;
			height: 47px;
			line-height: 47px;
			border: 0 !important;
			padding: 0px 20px 0px 0px !important;
			@include fs-14;
			@include fw-400;
			text-transform: none;

			&::after {
				border:0;
				color: $primary-color;
				@include font-icon;
				@include fs-22;
				content: "keyboard_arrow_down";
			}

			&:hover {
				color: $secondary-color;
				background-color: transparent!important;
				box-shadow: none;
			}

			&:focus {
				outline: none !important;
				outline-offset: 0 !important;
				box-shadow: none !important;
			}
		}
		*/
		
		.mainSearchButton {
			display: inline-block;
			float: right;
			height: 23px;
			width: 53px;
			line-height: 23px;
			margin:11px 0px 11px 0;
			background-color: transparent;
			color: var(--bs-primary);
			text-decoration: none;
			@include fs-22;

			&:focus {
				outline: none;
			}
			
			@media (max-width: 576px) {
				padding-right: 10px;
				padding-left: 10px;
			}
		}
		
		.advanced {
			@include fs-13;
			position: absolute;
			top: auto;
			bottom: -35px;
			right: 5px;
			padding: 2px 10px;
			font-weight: 400;
			
			@media (min-width:767px) {
				top: -27px;
				bottom: auto;
				right: 10px;
				
			}
		}
	}
}

@media (min-width:767px) {

	.main-search-panel {

		#search-close {
			display: none;
		}

	}

}

@media (max-width: 767px) {

  .main-search-panel {

    display: none;

    z-index:1034;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;
    margin: 0;

    background-color: rgba(50, 50, 50, 0.5);

    &.show {
      display:block;
    }

    #search-form {

      position: absolute;

      border-radius: 4px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

      margin:22px 0;

      left:3%;
      right:3%;

      width: 92%;
      height:47px;

      background-color: $white;

      input {
        width: 50%;
      }

      #search-close {
        color: $primary-color;
        width:47px;
        text-align: center;
        line-height: 47px;
        display: block;
        cursor: pointer;
      }
    }
  }

}


@media (max-width: 576px) {

	.main-search-panel {

		#search-form {
			left:2%;
			right:2%;

			.searchtext {
				@include fs-12;
				width: 60%;
			}
			.dropdown-toggle {
				@include fs-12;
				letter-spacing: normal;
			}
		}
	}
}
