/* header and navigation */

// iOS fix
.ios-status-bar:before {
	content: "";
	position: fixed;
	z-index: 10000;
	top: -100px;
	height: 100px;
	right: 0;
	left: 0;
	background-color: #353535;
}

header {
	min-width:100%;

	.navbar-supertop {
		height: 36px;
		background-color: $primary-dark;
		justify-content: space-between;

		&-social {
			a {
				display: inline-block;
				height: 36px;
				padding: 0px 3px;
				margin: 0px 2px;
				//height: auto!important;
				//margin: auto;
				//border-radius: 3px;
				//padding: 0 3px;
				//color: $primary-light !important;

				img {
					margin-top: 10px;
					height: 15px;
					width: 15px;
				}
			}
		}

		&-locales {

			@include fs-12;

			a {
				color: $primary-light !important;
				&:hover {
					text-decoration: underline;
				}

			}
		}

		ul {
			//display: flex;
			//flex-direction: row;
			//width: auto;
			//margin:0;
			//padding:0;
			//list-style: none;

			li {
				display: inline-block;
				height: 36px;

				a {
					height: 36px;
					line-height: 36px;
					display: inline-block;
				}
			}

			.dropdown-toggle {
				height:36px;
				
				&:after {
					display: none;
				}
				//i.material-icons {
					//display: inline-block;
					//height:36px;
					//vertical-align: middle !important;
					//color: $primary-color;
					//line-height:32px;
					//font-size:22px;
				//}
			}

			&.dropdown-menu {
				display: none;
				z-index: 9999;
				&.menu-currencies {
					width: 206px;
					
					.dropdown-item {
						width: 65px;
						display: inline-block;
					}
				}

				&.menu-locales {
					width: 268px;
					.dropdown-item {
						width: 130px;
						display: inline-block;

						a {
							padding: 0px 10px;
							text-align: left;
						}

					}
				}

				.dropdown-item {
					//line-height: 36px;
					//height: 36px;
					//text-align: center;
					padding: 0;
				}
				a {
					color: #353535 !important;
					display: block;
					text-decoration: none;
					font-size: 14px;
				}
			}

			&.show {
				display: block;
			}
		}
		
		.header-chevron {
			width: 1em;
			display: inline-block;
		}

		.header-chevron:before {
			color: var(--bs-primary);
			border-style: solid;
			border-width: 0.22em 0.22em 0 0;
			content: '';
			display: inline-block;
			height: 8px;
			width: 8px;
			margin-bottom: 3px;
			transform: rotate(135deg);
		}
	
	}
	
	nav {
		
		&.desktopmenu {
			height: 58px;
			padding-top: 7px;
			
			a.nav-link {
				font-size: 13px;
			}
		}
		
		.navbar-brand {
			img {
				height: 28px;
				width: auto
			}
		}
		
		.nav-underline {
			a.nav-link {
				font-size: 14px;
				color: #767676;
				
				&.active {
					color: $primary-color;
				}
			}
		}
		
		section {
			margin-bottom: 20px;
		}

		#offcanvas-main-menu {
			.nav-mob-logo {
				img {
					height: 28px;
					width: auto
				}
			}
		}

		#navbarNavDropdown {
			.dropdown-item {
				font-size: 13px;
			}
			
			.subnav {
				
				.dropdown-item {
					font-size: 12px;
				}
			
			}
		}
		.dropdown-item a {
			color: #353535 !important;
			display: block;
			margin: 0;
		}
		
		#mobile-nav-filters-content {
		
			.header {
				font-size: 14px;
				font-weight: bold;
			}
			
			.colors {

				.squares {

					padding: 15px;

					a.color {

						display: inline-block;
						width: 30px;
						height: 30px;
						margin-bottom: 10px;
						border: 1px solid #888;
						text-align: center;
						font-weight: 700;
						color: $notify-red;

						&:hover {
							border: 1px solid #aaa;
						}

						.ico {
							font-size: 19px; color: #fff;
						}

					}
				}
			}
			
			.list {
				.box {
					.item {
						width: 100%;
						font-size: 13px;
						
						.name {
							display: flex;
							justify-content: space-between;
							color: #000;
							padding: 6px 0px;

							.count {
								color: #fb642d;
							}
						}
					}
				}
			}
			
		}
		
		#mobile-nav-categories-content {
		
			.list-group-item {
				border: 0;
				border-radius: 0;
				padding: 5px 0 5px 5px;

				a {
					color: #000;
					font-size: 13px;

					span {
						color: #fb642d;
					}
				}

				&.session-name {
					display: flex;
					justify-content: space-between;
					border-top: 1px solid #ddd;
					color: $secondary-dark;
					min-height: 50px;
					background-color: #f5f5f5;

					&:hover {
						cursor: pointer;
						color: #888;
					}

					.icon {
						font-size: 20px;
						color: $primary-color;
					}

					&:not(.open) {
						.arrow_up {
							display: none;
						}
					}

					&.open {
						.arrow_down {
							display: none;
						}
					}
				}

				&.session-list {
					display: none;

					&.open {
						display: block;
					}

				}
			}

			.icons {
				width: 20px;
				height: 16px;
				i {
					margin-top: -5px;
				}
			}

			a.item {
				color: $secondary-dark;
				padding: 6px 0px;
				display: block;
				
				&.active {
					font-weight: bold;
				}
				
				i {
					color: $primary-color;
					font-size: 20px;
				}
				
				.name {
					color: black;
					display: flex;
					justify-content: flex-start;

					.count {
						color: #fb642d;
					}
				}
				
				&:not(.open) {
					.arrow_up{
						display: none;
					}
				}
				
				&.open {
					.arrow_down{
						display: none;
					}
				}
				
			}
		}
		
		#mobile-nav-menu-content {
			a {
				color: #767676;
				font-size: 14px;
				padding: 6px 0;
				display: block;

				&.active {
					border-bottom-right-radius: 22px;
					background-color: #f5f5f5;
					border-top-right-radius: 22px;
				}
			}
		}
		
	}

	.nav-user {
		margin:0;
		@include flex-row;
		height: auto;
		
		.btn {
			@include fw-600;
			@include fs-12;
		}

		a {

			&.user-circle {

				position: relative;

				span.user {
					display: inline-block;
					height: 40px;
					line-height:40px;
					width:40px;
					background-color: var(--bs-primary);
					color: $white;
					text-align: center;
					@include font-head;
					@include fs-18;

				}
				
				#notificationsdot {
					top: -5px;
					right: -5px;
				}

				@media (max-width: 992px) {
					span.user {
						width: 28px;
						height: 28px;
						line-height: 28px;
						@include fs-13;
					}
				}

			}

		}

		li {
			align-self: center;

			.mob-search {
				font-size: 24px;
			}

			&.cart-item {
				
				a {
					font-size:24px;
					position: relative;

					span.counter {
						@include font-head;
						background-color: $notify-green;
						color: $white;
						width: 16px;
						height: 16px;
						font-size: 10px;
						text-align: center;
						border-radius: 50%;
						display: block;
					}
					
				}
			
			}

			/*
			&.mobile-cart {

				a {
					padding-top: 9px !important;
					position: relative;
				}

				span.counter {
				}

			}
			*/
			
		}


	}
	
}

#pageMoreOptionsButton {
	position: fixed;
	top: 120px;
	right: 30px;
	z-index: 952;
	border-radius: 50%;
	display: block;
	width: 34px;
	height: 34px;
	padding: 0px;
	font-size: 25px;
	line-height: 34px; 
	-webkit-transition: all 2s ease;  
	-moz-transition: all 0.3s ease;  
	-o-transition: all 0.3s ease;  
	-ms-transition: all 0.3s ease;  
	transition: all 0.3s ease;
	
	&.rotate {
		-webkit-transform: rotate(45deg);
		-moz-transform: rotate(45deg);
		-o-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
		
	}
}
#pageMoreOptionsContainer {
	position: fixed;
	overflow: hidden;
	left: 0px;
	top: 0px;
	z-index: 951;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, .7);
	
	.ico {
		font-size: 20px;
		line-height: 32px;
	}
	
	.controls {
		z-index: 1201;
		position: fixed;
		right: 30px;
		top: 180px;
		
		.control {
			border-radius: 50%;
			display: block;
			width: 34px;
			height: 34px;
			padding: 0px;
			margin-bottom: 8px;
		}
	}

	.section {
		background: #484848;
		padding: 20px;
		width: 70%;
		border-right: 5px solid #fff;
		overflow-y: auto;
		overflow-x: hidden;
	
		.title {
			@include fs-20;
			@include fw-700;
			color: $white;
			margin-bottom: 15px;
		}
		
		.head {
			border-radius: 14px;
			background: #fff;
			margin: 20px 0px 10px 0px;
			padding: 10px;
			font-size: 14px;
		
			.icon {
				font-size:18px;
				text-align:center;
				vertical-align:inherit;
				border-radius:50%;
				width:30px;
				height:30px;
				line-height:30px;
				background:rgba(106,45,251,.05);
				color: #6a2dfb;
				margin-top:-5px;
				margin-right:10px;
			}
			
			.name {
				@include font-head;
			}
			
			.date {
				font-size:12px;
			}
			
			img {
				height:40px;
			}
		}
		
		.item {
			padding: 0px 5px;
			
			.lot {
				border-radius: 14px;
				background: #fff;
				margin-bottom: 10px;
				padding:5px;
			}
		}
		
		.empty {
			color: #fff;
		}
	}
}

@media screen and (max-width: 575.98px) {
	#pageMoreOptionsButton {
		top: 100px;
		right: 8px;
	}
	#pageMoreOptionsContainer {
		.controls {
			top: 145px;
			right: 8px;
		}
		.section {
			width:80%;
		}
	}
}


// START FOOTER

footer {

	.footer-nav {
		background-color: $primary-dark;
		padding: 50px 30px 15px 30px;

		.col-md-3:last-of-type {
			padding-right: 48px;
		}

		.section {
			color: $white;
			@include font-main;
			@include fs-18;
			@include fw-500;
			margin-bottom: 10px;
		}

		p {
			line-height: 1.42;
			margin-bottom: 20px;
			color: $white;
			@include fs-12;
		}

		form {
			label {
				@include fs-12;
				color: $white;
				opacity: 0.6;
			}
			input {
				width:100%;

				&[type=text] {
					height: 38px;
					padding: 0 13px;
					line-height: 38px;
					border-radius:38px;
					border:0;
					background-color: $primary-light;
					color: $gray-dark;
					@include fs-14;
				}

				&[type=submit] {
					margin: 15px 0;
				}
			}
		}

		ul {
			margin:0 0 20px 0;
			padding:0;

			li {
				line-height: 1.86;
				list-style: none;

				a {
				color: #aaa;
				text-decoration: none;
				@include fs-14;

				&:hover {
					color: #fff;
				}
				}
			}
		}
		
		#footer-social {
			a {
				img {
					width: 26px;
					height: 26px;
				}
			}
			
			@media (max-width: 768px) {
				li {
					display: inline-block;
				}
			}

		}
	}

	.languages {
		padding-top: 10px;
		border-top: 1px solid #444;
		color: #555;

		@media (max-width: 768px) {
			padding-bottom: 15px;
		}

		a {
			display: inline-block;
			color: #aaa;
			padding: 5px 5px 5px 5px;
			text-decoration: none;
			@include fs-12;

			@media (max-width: 768px) {
				padding: 4px 7px 4px 7px;
				margin-bottom: 4px;
				@include fs-13;
			}

			&:hover {
				color: #fff;
			}
		}

	}

	.newsletterinput {

		&.mdc-text-field {
			&:after, &:before {
				top: -50%;
				left: -50%;
				width: auto;
				height: auto;
			}
		}

	}

	.footer-copy {
		height:43px;
		line-height:43px;
		background-color: #414141;
		color: #f3f6f8;
		@include fs-11;
		text-align: center;
	}

}

@media (max-width:991px) and (min-width:769px) {
	footer {
		#footer-newsletter-block {
			border-top:1px #555 solid;
			padding-top:15px;
		}
	}
}

@media (min-width: 769px) {

	footer {

		.footer-nav {
			.section {
				span.icon {
					display: none;
				}
			}
		}
	}
}

@media (max-width: 768px) {

	footer {

		.footer-nav {
			background-color: $primary-dark;
			padding: 30px 15px 10px 15px;

			.section {
				cursor: pointer;
				position: relative;
				color: $white;
				margin-bottom: 15px;
				@include font-main;
				@include fs-14;
				@include fw-500;

				span.icon {
					position: absolute;
					right: 0;
					top: 0;
					margin-top: -3px;
					color: #fff;
				}
			}

			.show {
				display: block!important;
			}

			p {
				@include fs-12;
				color: $white;
				margin-bottom: 15px;
			}

			/*
			form {
				label {
				@include fs-12;
				color: $white;
				opacity: 0.6;
				}
				input {
				width:100%;

				&[type=text] {
					height: 38px;
					padding: 0 13px;
					line-height: 38px;
					border-radius:38px;
					border:0;
					background-color: $primary-light;
					color: $gray-dark;
					@include fs-14;
				}

				&[type=submit] {
					margin: 15px 0;
				}
				}
			}
			*/

			ul {
				margin:0 0 20px 0;
				padding: 0px;

				li {
					line-height: 1.86;
					list-style: none;

					a {
						@include fs-14;
						color: $white;
						opacity:0.7;
						text-decoration: none;
					}
				}
			}
		}
	}
} // @media (max-width: 768px) EO
