/* flex mixing stuff copy from bootstrap more handy */


@mixin d-flex {
  display: flex;
  display: -ms-flexbox;
}

@mixin flex-b-auto {
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
}

@mixin flex-row {
  -ms-flex-direction: row;
  flex-direction: row;
}

@mixin flex-nowrap {
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
}

@mixin flex-start {
  -ms-flex-pack: start;
  justify-content: flex-start;
}

@mixin a-center {
  -ms-flex-align: center;
  align-items: center;
}

@mixin clearfix() {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}
