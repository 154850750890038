
.material-icons {
	font-family: 'Material Icons';
	font-style: normal;
	letter-spacing: normal;
	text-transform: none;
	white-space: nowrap;
	word-wrap: normal;
	direction: ltr;
	-webkit-font-feature-settings: 'liga';
	-webkit-font-smoothing: antialiased;
	display: inline-flex; 
	vertical-align: top;
/* 	text-indent: -9999px; */
	
	&::after {
		text-indent: 0;
		content: " ";
	}
	
	&::before {
		display: none;
	}

}

@font-face {
	font-family: 'Material Icons';
	font-style: normal;
	font-weight: 400;
	font-display: block;
	src: url('https://img1.one.bid/fonts/MaterialIcons-Regular-4.0.ttf') format('truetype');
}

.fs-2x {font-size: 2em;}
.fs-3x {font-size: 3em;}
.fs-4x {font-size: 4em;}
.fs-5x {font-size: 5em;}

.fs-9 {
  font-size:9px!important;
}

.fs-10 {
  font-size:10px!important;
}

.fs-11 {
  font-size:11px!important;
}

.fs-12 {
  font-size:12px!important;
}

.fs-13 {
  font-size:13px!important;
}

.fs-14 {
  font-size:14px!important;
}

.fs-15 {
	font-size:15px!important;
}

.fs-16 {
  font-size:16px!important;
}

.fs-18 {
  font-size:18px!important;
}

.fs-20 {
  font-size:20px!important;
}

.fs-22 {
  font-size:22px!important;
}

.fs-24 {
	font-size: 24px!important;
}

.fs-26 {
	font-size: 26px!important;
}

.fs-28 {
	font-size: 28px!important;
}

.fs-30 {
	font-size: 30px!important;
}

.fs-40 {
	font-size: 40px!important;
}

.fs-50 {
  font-size: 50px!important;
}

.fs-70 {
  font-size: 70px!important;
}

.text {
  &-warning {
    color: $notify-yellow!important;
  }
  &-dark {
    color: $primary-dark!important;
  }
  &-danger {
    color: $notify-red!important;
  }
  &-success {
    color: $notify-green!important;
  }
  &-muted {
    color: $gray-dark!important;
  }
  &-info {
    color: $notify-blue!important;
  }
}

.fw {
  &-300 {
    font-weight: 300;
  }
  &-400 {
    font-weight: 400;
  }
  &-500 {
    font-weight: 500;
  }
  &-600 {
    font-weight: 600;
  }
  &-700 {
    font-weight: 700;
  }
}

.badge {
  &-warning {
    background-color: $notify-yellow!important;
    color: $white;
  }
  &-danger {
    background-color: $notify-red!important;
    color: $white;
  }
  &-success {
    background-color: $notify-green!important;
    color: $white;
  }
  &-muted {
    background-color: $gray-light!important;
    color: $primary-dark;
  }
  &-info {
    background-color: $notify-blue!important;
    color: $white;
  }
  &-dark {
    background-color: $primary-dark!important;
    color: $white;
  }
}
