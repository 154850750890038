/* all buttons stuff */

.btn {
	padding: 0.3rem 1rem 0.23rem;
	text-transform: uppercase;
	@include fw-500;
	font-size: 0.85rem;
	letter-spacing: 0.07em;

	&:hover {
		box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
	}

	&.btn-sm {
		padding: 0.25rem 0.75rem 0.2rem;
		font-size: 0.72rem;
	}

	&.btn-link {
		padding:0;
		margin:0;
		@include fw-600;
		color: var(--bs-primary);
		text-decoration: none;

		&:hover {
			box-shadow: none;
			color: $secondary-color;
			text-decoration: underline !important;
		}
	}


	&.btn-disabled {
		background-color: $gray-superlight !important;
		color: $gray-dark !important;
	}

	&.btn-active {
		background-color: $gray-superlight !important;
		color: $primary-color;
	}

	&.btn-shadow {
		box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.04);
	}
}
