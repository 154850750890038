.nav, .tabs {
  padding:0;
  height: 58px;

  .tab {
    height:58px;
    line-height: 58px;
    @include fs-14;
    color: $primary-dark;

    display: inline-block;
    list-style: none;

    a {
      display: inline-block;
      line-height: inherit;
      height: inherit;
      padding: 0 10px;
      color: inherit;

      &:hover {
        background-color: $primary-light;
      }
      &.active {
        border-bottom: 2px $primary-color solid;
        color: $primary-color;
      }

    }
  }
  &.lite {
    .tab {
      a {
        &:hover {
          background-color: $secondary-light;
        }
      }
    }
  }
}
