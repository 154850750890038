@import 'vars-colors.scss';
@import 'mix-fonts.scss';

@import "material-components-web/material-components-web";

label, input {
  margin:0;
  padding:0;
}

.onebid-forms {

  h1 {

    &.form-shyhead {
      @include font-main;
      @include fs-18;
      @include fw-500;
      line-height:1.5;
      margin: 20px 0;
      color: $superdark;
    }

    &.form-mainhead {
      @include font-head;
      color: $primary-dark;
      font-size: 40px;
      margin: 0 auto 20px auto;
	  text-align: center;
    }

    &.form-midhead {
      @include font-head;
      color: $primary-dark;
      @include fs-28;
      margin: 0 auto 5px auto;
    }
  }

  .card {
	position: static;
    padding: 10px 10px;
    margin:0 auto;
    border:0;

    .card-header {
      border:0;
      background-color:transparent;
      @include fs-18;
      @include fw-500;
      color: $superdark;
      padding: 17px 0;
      min-height: 60px;
      line-height: 26px;
    }

    .card-body {
      padding:0;
      margin:0;
    }
  }

  .onebid-mdc-group-head {
    @include fs-12;
    color: $gray-light;
    line-height: 1.5;
  }

  .onebid-mdc-icon-24 {
    position: absolute;
    top: 16px;
    right: 22px;
    height: 24px;
  }

  .form-errors {

    background-color: rgba($notify-red, 0.85);
    color: $white;
    @include fs-14;
    border-radius: 8px;
    opacity: 0.8;
	margin-top:3px;

    ul {
      padding:5px 20px;
      margin:0;
      list-style-type: none;
      li {
        line-height: 1.3;
        padding:2px;
      }
    }
  }

  .mark-required {
    color: $notify-red;
  }

  .mdc-form-field {
    margin: 20px 0 10px 0;
    @include font-main;
    @include fs-16;
	z-index: 10;

    &.mark-error {
      label {
        color: $notify-red;
      }
    }

    &.small-margin {
      margin: 0 0 5px 0;
    }

    label.onebid-check-label {
        cursor: pointer;
		font-size: 13px;
      }

    .mdc-radio {
      // @include mdc-radio-unchecked-stroke-color($mdc-radio-unchecked-color, $query);
      // @include mdc-radio-checked-stroke-color($mdc-radio-baseline-theme-color, $query);
      @include mdc-radio-ink-color($primary-color);
      @include mdc-radio-focus-indicator-color($secondary-color);
    }

    .mdc-switch {
      margin-right: 22px;

      &.mdc-switch--checked {
        .mdc-switch__track {
          background-color: $primary-color;
		  color: $primary-color;
          opacity: 0.8;
        }
      }
    }
  }

	.mdc-text-field {
		width:100%;
		background-color: $primary-light;
		border-radius: 8px;
		margin: 30px 0 0 0;
		overflow: visible;
		z-index: 10;
		
		&:hover {
			background: #ececec;
		}
		
		&::before {
			width: 0;
			height: 0;
		}
		
		&.mark-error {
			border: 1px $notify-red solid;
		}

		&:not(.mdc-text-field--textarea) {
			height: 56px;
		}

		.toggle-pass {
			color: $primary-color;
			cursor: pointer;
			font-size: 24px;
			&:hover {
				color: $secondary-color;
			}
			&.toggle-on {
				color: $notify-green-light;
				&:hover {
					color: $notify-green;
				}
			}
		}
		
		img.countryflag {
			height: 12px;
			float: right;
			margin-top: 5px;
			margin-left: 5px;
			-webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.38);
			-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.38);
			box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.38);
		}

		&:first-of-type {
			margin: 0;
		}

		.mdc-text-field__input {
			border:0;
			padding: 22px 20px 4px;
			@include font-main;
			@include fs-16;
			color: $secondary-dark;

			&:hover {
				border:0;
			}
		}

		.mdc-floating-label {
			@include font-main;
			line-height: 1.56;
			color: $gray-dark2;
			left: 20px;
			top: 28px;

			&--float-above {
				color: $gray_dark;
				top: 30px;
			}
		}

		&.mdc-text-field--textarea {
			height: 260px;
		
		}

		.mdc-notched-outline__notch .mdc-floating-label {
			left: 7px;
		}
		
		&.useiti {
			input {
				padding-left: 53px;
			}
			label {
				padding-left: 45px;
			}
		}
		
		.iti {
			width: 100%;
		}
	
	}

  .mdc-text-field:not(.mdc-ripple-upgraded):focus::before,
  .mdc-text-field.mdc-ripple-upgraded--background-focused::before {
    opacity:0;
  }

  .mdc-text-field--outlined {
    .mdc-text-field__input {
      padding: 10px 20px 4px;
    }
  }
  
	.mdc-select {
		width: 100%;
		border-radius: 8px;
		margin: 30px 0 0;
		overflow: visible;

		.mdc-select__menu {
			width: 100%;
			z-index: 20;
		}
		
		.mdc-select__selected-text {
			border-bottom: 0px;
		}
	
		.mdc-select__anchor {
			width: 100%;
			border-radius: 8px;
		}
		
	}

  .tt-menu {
    background-color: $primary-light;
    color: $secondary-dark;
    width: 390px;
    .tt-dataset {
      width:100%;
      .tt-suggestion {
        line-height: 2em;
        padding-left: 20px;

        &.tt-cursor {
          background-color: $color-superlight;
        }
      }
    }
  }

  .mdc-notched-outline--notched .mdc-floating-label {
      left:4px!important;
  }

  // .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label

  .form-group {

    margin: 20px 0 10px 0;

    &:first-of-type {
      margin: 0 0 10px 0;
    }

    .btn[type=submit] {
      margin:0;
    }

    &.form-squeezed {
      height: 56px;
      background-color: $primary-light;
      border-radius: 8px;
      border:0;
      padding: 5px 20px;
      cursor:text;

      label {
        @include fs-12;
        line-height: 12px;
        height:12px;
        color: $gray-dark;
      }

      input, input.form-control {
        border:0;
        background-color: $primary-light;
        color: $secondary-dark;
        margin-top:-2px;
        @include fs-16;
        line-height: 1.5;
        width:95%;
        height:24px;
        padding:0;
        display: block;
        border-radius: 0;
        transition: none;

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          background-color: $primary-light!important;
          color: $secondary-dark!important;
          border:0;
        }
      }
    }
  }
}

@media (max-width: 575px) {

  .onebid-forms {

    h1.form-mainhead {
      margin-left: 0;
      margin-right: 0;
    }

    .form-group {

      .btn[type=submit] {
        width:100%;
      }
    }

  }

} // @media (min-width: 576px) EO
